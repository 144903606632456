import Constants from "../constants/Constants";

export function getUAASServiceBaseURL(stage, region) {
    switch(stage) {
        case Constants.Stage.BETA:
            return Constants.DolphinURL.NA.PREPROD;
        case Constants.Stage.GAMMA:
            switch(region) {
                case Constants.Region.REGION_NA: return Constants.DolphinURL.NA.PREPROD;
                case Constants.Region.REGION_EU: return Constants.DolphinURL.EU.PREPROD;
                case Constants.Region.REGION_FE: return Constants.DolphinURL.FE.PREPROD;
                case Constants.Region.REGION_IN: return Constants.DolphinURL.EU.PREPROD;
                // no default
            }
            break;
        // no default
        case Constants.Stage.PROD:
            switch(region) {
                case Constants.Region.REGION_NA: return Constants.DolphinURL.NA.PROD;
                case Constants.Region.REGION_EU: return Constants.DolphinURL.EU.PROD;
                case Constants.Region.REGION_FE: return Constants.DolphinURL.FE.PROD;
                case Constants.Region.REGION_IN: return Constants.DolphinURL.EU.PROD;
                // no default
            }
            break;
        // no default
    }
}

export const getAssociateExperienceBaseURL = (stage, region) => {
    switch (stage) {
        case Constants.Stage.BETA:
            switch (region) {
                case Constants.Region.REGION_NA:
                    return Constants.AssociateExperienceURL.NA.BETA;
                case Constants.Region.REGION_EU:
                    return Constants.AssociateExperienceURL.EU.BETA;
                case Constants.Region.REGION_FE:
                    return Constants.AssociateExperienceURL.FE.BETA;
                case Constants.Region.REGION_IN:
                    return Constants.AssociateExperienceURL.IN.BETA;
                // no default
            }
            break;
        case Constants.Stage.GAMMA:
            switch (region) {
                case Constants.Region.REGION_NA:
                    return Constants.AssociateExperienceURL.NA.GAMMA;
                case Constants.Region.REGION_EU:
                    return Constants.AssociateExperienceURL.EU.GAMMA;
                case Constants.Region.REGION_FE:
                    return Constants.AssociateExperienceURL.FE.GAMMA;
                case Constants.Region.REGION_IN:
                    return Constants.AssociateExperienceURL.IN.GAMMA;
                // no default
            }
            break;
        case Constants.Stage.PROD:
            switch (region) {
                case Constants.Region.REGION_NA:
                    return Constants.AssociateExperienceURL.NA.PROD;
                case Constants.Region.REGION_EU:
                    return Constants.AssociateExperienceURL.EU.PROD;
                case Constants.Region.REGION_FE:
                    return Constants.AssociateExperienceURL.FE.PROD;
                case Constants.Region.REGION_IN:
                    return Constants.AssociateExperienceURL.IN.PROD;
                // no default
            }
            break;
        // no default
    }
};

export const API_ROUTES = {
    UAAS : {
        GetModulesAndFeaturesForNodeRequest :"uaas/user/modulesandfeatures/get"
    },
    LMFS: {
        FetchUpdateConsentStatusRequest: "lmfs/insights/fetchUpdateConsentStatus",
        FetchInductMetricsRequest: "lmfs/insights/fetchInductMetrics",
        FetchStowMetricsRequest: "lmfs/insights/fetchStowMetrics"
    },
    ASSOCIATE_EXPERIENCE: {
        CreateNotificationRequest: "/create-notification",
        DisplayNotificationsRequest: "/"
    }
};

export const REDIRECT_HASH = {
    ASSOCIATE_EXPERIENCE: {
        InsightSummaryRequest: "#/insight/summary",
    }
}